import React, { ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { createRequest, GraphQLRequest, useClient } from 'urql';
import { useParams } from 'react-router-dom';
import { carSpecByLicensePlateQuery, carSpecByVinQuery } from './gql/queries';
import CarSpecContext from './context';
import { mapCarData } from './utils';
import { RouteParams } from '../../routes';
import { ICarSpecContext } from './types';
import { useValuationResult } from '../../hooks/useValuationResult';
import {
  CarSpecByLicensePlateQuery,
  CarSpecByLicensePlateQueryVariables,
  CarSpecByVinQuery,
  CarSpecByVinQueryVariables,
} from '../../@types/gql';

const VIN_LENGTH = 17;

interface IProps {
  children: ReactNode;
}

export const CarSpecProvider = ({ children }: IProps) => {
  const { licensePlateOrVin, odometerStatus: urlOdometerStatus } = useParams<RouteParams>();
  const [queryResult, setQueryResult] = useState<
    CarSpecByVinQuery | CarSpecByLicensePlateQuery | null
  >(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const noOfKilometers = parseInt(urlOdometerStatus || '0', 10) * 10;

  const currentLicensePlateOrVin = useRef<string | null>(null);
  const currentCarSpec = useRef<ICarSpec | null>(null);

  const isInitialQuery =
    Boolean(licensePlateOrVin) && currentLicensePlateOrVin.current !== licensePlateOrVin;

  const client = useClient();

  useEffect(() => {
    if (!licensePlateOrVin) {
      return;
    }

    const graphQLRequest: GraphQLRequest<
      CarSpecByVinQuery | CarSpecByLicensePlateQuery,
      CarSpecByVinQueryVariables | CarSpecByLicensePlateQueryVariables
    > =
      licensePlateOrVin.length === VIN_LENGTH
        ? createRequest(carSpecByVinQuery, {
            vin: licensePlateOrVin,
            noOfKilometers,
            isInitialQuery,
          })
        : createRequest(carSpecByLicensePlateQuery, {
            licensePlate: licensePlateOrVin,
            noOfKilometers,
            isInitialQuery,
          });

    setLoading(true);

    client
      .executeQuery(graphQLRequest)
      .toPromise()
      .then(({ data }) => {
        setQueryResult(data ?? null);
      })
      .catch((error) => {
        setQueryResult(null);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [licensePlateOrVin, noOfKilometers, client]);

  const valuation = useValuationResult(queryResult);

  const carData = queryResult?.car.at(0);

  useEffect(() => {
    if (carData?.vid && licensePlateOrVin) {
      currentLicensePlateOrVin.current = licensePlateOrVin;
    }
  }, [carData?.vid]);

  const carSpec = useMemo(() => {
    if (!licensePlateOrVin || !carData?.carKey) {
      return currentCarSpec.current;
    }

    currentCarSpec.current = {
      ...currentCarSpec.current,
      ...mapCarData(carData),
    };

    return currentCarSpec.current;
  }, [carData, licensePlateOrVin, currentCarSpec.current]);

  const contextValue: ICarSpecContext = {
    carSpec,
    valuation,
    valuationInput: {
      licensePlateOrVin,
      odometerStatus: noOfKilometers / 10,
    },
    loading: isInitialQuery && loading,
    loadingValuation: !isInitialQuery && loading,
    error,
  };

  return <CarSpecContext.Provider value={contextValue}>{children}</CarSpecContext.Provider>;
};

export const useCarSpecContext = () => {
  const context = useContext(CarSpecContext);
  if (context === undefined) {
    throw new Error('useCarSpecContext can only be used inside CarSpecProvider');
  }
  return context;
};
