import gql from 'graphql-tag';
import { CAR_SPEC, VALUATION } from '../../../gql/fragments';

export const carSpecByLicensePlateQuery = gql`
  ${CAR_SPEC}
  ${VALUATION}

  query CarSpecByLicensePlateQuery(
    $licensePlate: [String!]!
    $noOfKilometers: InferencePropertyValue!
    $extraMonthsAhead: InferencePropertyValue = 0
    $isInitialQuery: Boolean!
  ) {
    car: specificationByLicensePlate(licensePlate: $licensePlate) {
      ...CarSpec @include(if: $isInitialQuery)
      ...Valuation
    }
  }
`;

export const carSpecByVinQuery = gql`
  ${CAR_SPEC}
  ${VALUATION}

  query CarSpecByVinQuery(
    $vin: [String!]!
    $noOfKilometers: InferencePropertyValue!
    $extraMonthsAhead: InferencePropertyValue = 0
    $isInitialQuery: Boolean!
  ) {
    car: specificationByVin(vin: $vin) {
      ...CarSpec @include(if: $isInitialQuery)
      ...Valuation
    }
  }
`;
