import React, { ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import MarketInsightsContext from './context';
import { mapMarketInsights } from './utils';
import { RouteParams } from '../../routes';
import { IMarketInsightsContext } from './types';
import { useCarSpecContext } from '../CarSpecProvider';
import {
  MarketInsightsByLicensePlaceQuery,
  MarketInsightsByLicensePlaceQueryVariables,
  MarketInsightsByVinQuery,
  MarketInsightsByVinQueryVariables,
} from '../../@types/gql';
import { createRequest, GraphQLRequest, useClient } from 'urql';
import { marketInsightsByLicensePlaceQuery, marketInsightsByVinQuery } from './gql/queries';

const VIN_LENGTH = 17;

interface IProps {
  children: ReactNode;
}

export const MarketInsightsProvider = ({ children }: IProps) => {
  const { licensePlateOrVin } = useParams<RouteParams>();
  const { carSpec } = useCarSpecContext();

  const client = useClient();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [data, setData] = useState<
    MarketInsightsByVinQuery | MarketInsightsByLicensePlaceQuery | null
  >(null);

  const currentMarketInsights = useRef<IMarketInsights | null>(null);

  useEffect(() => {
    if (!licensePlateOrVin) {
      return;
    }

    const graphQLRequest: GraphQLRequest<
      MarketInsightsByVinQuery | MarketInsightsByLicensePlaceQuery,
      MarketInsightsByVinQueryVariables | MarketInsightsByLicensePlaceQueryVariables
    > =
      licensePlateOrVin.length === VIN_LENGTH
        ? createRequest(marketInsightsByVinQuery, { vin: licensePlateOrVin })
        : createRequest(marketInsightsByLicensePlaceQuery, { licensePlate: licensePlateOrVin });

    setLoading(true);

    client
      .executeQuery(graphQLRequest)
      .toPromise()
      .then(({ data }) => {
        setData(data ?? null);
      })
      .catch((error) => {
        setData(null);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [licensePlateOrVin, client]);

  const marketInsightsData = data?.car?.[0];

  const marketInsights = useMemo(() => {
    if (!marketInsightsData?.similarCarsCurrentlyPublished) {
      return currentMarketInsights.current;
    }

    currentMarketInsights.current = {
      ...currentMarketInsights.current,
      ...mapMarketInsights(marketInsightsData, carSpec),
    };

    return currentMarketInsights.current;
  }, [carSpec, marketInsightsData, currentMarketInsights.current]);

  const contextValue: IMarketInsightsContext = {
    marketInsights,
    loading,
    error: error?.message,
  };

  return (
    <MarketInsightsContext.Provider value={contextValue}>{children}</MarketInsightsContext.Provider>
  );
};

export const useMarketInsightsContext = () => {
  const context = useContext(MarketInsightsContext);
  if (context === undefined) {
    throw new Error('useMarketInsightsContext can only be used inside MarketInsightsProvider');
  }
  return context;
};
