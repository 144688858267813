import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import Loader from '../components/Loader';
import { useAnalytics } from '../features/Analytics';
import PrivateRoute from '../features/Auth/components/PrivateRoute';
import { CarSpecModalProvider } from '../features/CarSpecModal';
import { CarSpecProvider } from '../features/CarSpecProvider';
import Footer from '../features/Footer';
import { MarketInsightsProvider } from '../features/MarketInsightsProvider';

const MAINTENANCE_MODE = false;

const Home = React.lazy(() => import('./Home'));
const Contact = React.lazy(() => import('./Contact'));
const Valuation = React.lazy(() => import('./Valuation'));
const Login = React.lazy(() => import('./Login'));
const NotFound = React.lazy(() => import('./NotFound'));
const Maintenance = React.lazy(() => import('./Maintenance'));

export type RouteParams = { [index: string]: string | undefined };

const ValuationElement = ({ isAuthenticated }: { isAuthenticated: boolean }) => (
  <PrivateRoute isAuthenticated={isAuthenticated}>
    <CarSpecProvider>
      <CarSpecModalProvider>
        <MarketInsightsProvider>
          <Valuation />
        </MarketInsightsProvider>
      </CarSpecModalProvider>
    </CarSpecProvider>
  </PrivateRoute>
);

const Router = () => {
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const analytics = useAnalytics();

  const location = useLocation();

  useEffect(() => {
    analytics?.page();
  }, [location.pathname]);

  if (inProgress !== InteractionStatus.None) {
    return <Loader mt="40vh" />;
  }

  return (
    <React.Suspense fallback={<Loader mt="40vh" />}>
      <Routes>
        {MAINTENANCE_MODE ? (
          <Route path="*" element={<Maintenance />} />
        ) : (
          <>
            <Route element={<Login />} path="/login" />
            <Route
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <Contact />
                </PrivateRoute>
              }
              path="/kontakt-och-feedback"
            />
            <Route path="/valuation/:licensePlateOrVin">
              <Route
                path=":odometerStatus/:extraMonthsAhead"
                element={<ValuationElement isAuthenticated={isAuthenticated} />}
              />
              <Route
                path=":odometerStatus"
                element={<ValuationElement isAuthenticated={isAuthenticated} />}
              />
              <Route path="" element={<ValuationElement isAuthenticated={isAuthenticated} />} />
            </Route>
            <Route
              path="/"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <Home />
                </PrivateRoute>
              }
            />
            <Route path="*" element={<NotFound />} />
          </>
        )}
      </Routes>
      <Footer />
    </React.Suspense>
  );
};

export default Router;
