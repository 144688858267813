import gql from 'graphql-tag';
import { MARKET_INSIGHTS } from '../../../gql/fragments';

export const marketInsightsByLicensePlaceQuery = gql`
  ${MARKET_INSIGHTS}

  query MarketInsightsByLicensePlaceQuery($licensePlate: [String!]!) {
    car: specificationByLicensePlate(licensePlate: $licensePlate) {
      vin
      ...MarketInsights
    }
  }
`;

export const marketInsightsByVinQuery = gql`
  ${MARKET_INSIGHTS}

  query MarketInsightsByVinQuery($vin: [String!]!) {
    car: specificationByVin(vin: $vin) {
      vin
      ...MarketInsights
    }
  }
`;
